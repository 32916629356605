import styled from "styled-components";
import { Link } from 'react-router-dom';


export const FooterContainer = styled.footer`
    background-color: #101522;
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem;
    text-align: left;
    width: auto;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }

    @media screen and (width : 540px) {
        width: 100%;
    }
`;

export const CountryContainer = styled.div`
display: flex;
justify-content: space-between;
`;



export const FlagIcon = styled.img`
height: 25px;
width: 38px;
margin-right: 1rem;
`;



export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
    margin-top: 0.3rem;
`;

export const FooterLink = styled(Link)`
    color: #fff;
    text-dicoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    text-decoration: none;

    &:hover {
        color: #2C8BB0;
        transition: 0.3s ease-out;
    }
`;

export const AddressLine = styled.p`
    color: #fff;
    text-dicoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
`;

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;
    flex-direction: column;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;
export const VerifaviaCheckLogo = styled.img`
height: 55px;
width: 200px;
`;  

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    margin-top: 0.3rem;
`;

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
`;

export const SocialIconLink1 = styled.a`
    color: #fff;
    font-size: 24px;
`;
export const SocialIconLink2 = styled.a`
    color: #fff;
    font-size: 24px;
`;
export const SocialIconLink3 = styled.a`
    color: #fff;
    font-size: 24px;
`;

export const ContactIconWrap = styled.div`
    background: #fff;
    border-radius: 100px;
    padding: 10px 20px;
`;
export const ContactIcon = styled.a`
    color: #000;
    font-size: 16px;
    font-weight: bold;
`;